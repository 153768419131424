import React from "react"
import Content from "../grid/content"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../content/link"
import Rows from "../grid/rows"

const RelatedStory = ({ title, text, realSlug, featuredImage }) => {
  const img = getImage(featuredImage.localFile)

  return (
    <Content gap="md" paddingLeft="sm">
      <Link
        to={`/success-stories/${realSlug}/`}
        className="blog__related border--rounded"
      >
        <GatsbyImage
          className="blog__related__image"
          image={img}
          alt={featuredImage.alternativeText}
        />
      </Link>
      <Rows gap="xxs">
        <Link className="h4" to={`/success-stories/${realSlug}/`}>
          {title}
        </Link>
        <p className="text--small">{text}</p>
      </Rows>
    </Content>
  )
}

export default RelatedStory
