import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faStar } from "@fortawesome/free-solid-svg-icons"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Copy from "../../components/content/copy"
import Heading from "../../components/content/heading"
import { Columns, Content, Rows } from "../../components/grid"
import Main from "../../components/layout/main"
import SectionBackground from "../../components/containers/section-background"
import Seo from "../../components/seo/seo"
import CtaGroup from "@src/components/containers/cta-group"
import Button from "@src/components/interactive/button"
import { StaticImage } from "gatsby-plugin-image"
import RelatedStory from "@src/components/blog/related-story"

function SuccessStory({ data }) {
  const {
    seo,
    slug,
    cardSettings,
    coreDetails,
    heading,
    firstTextBlock,
    image,
    secondTextBlock,
    quoteBox,
    thirdTextBlock,
    relatedStories,
  } = data.strapiSuccessStories

  const { nodes: relatedNodes } = data.allStrapiSuccessStories

  const seoData = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: `/success-stories/${slug}/`,
  }

  const heroImage = getImage(cardSettings.cover.localFile)

  const inlineImg = getImage(image.localFile)
  let quoteImage

  if (quoteBox.enabled) {
    quoteImage = getImage(quoteBox.profilePicture.localFile)
  }

  return (
    <Main>
      <Seo customData={seoData} />

      <header>
        <Section theme="dark" wave="blog">
          <SectionBackground>
            <div className="blog-post__cover">
              <GatsbyImage
                className="width--full height--full blog-post__cover__image blog-post__cover__image--success-story"
                image={heroImage}
                alt={cardSettings.cover.alternativeText}
              />
            </div>
            <div className="blog-post__cover blog-post__cover__overlay" />
            <div className="width--full absolute height--full hero-image--success-story" />
          </SectionBackground>
          <SectionContent hero paddingBot="xl">
            <Rows gap="none">
              <Columns count="3,1">
                <Content gap="xl">
                  <Heading className="mt-sm" level={1}>
                    {heading.title}
                  </Heading>
                  <Copy className="mb-sm">
                    <p>{heading.postTitle}</p>
                  </Copy>
                  <div className="flex mb-sm">
                    {heading.statistics.map(
                      ({ coreStatText, postStatText }, index) => (
                        <div key={index} className="flex mr-sm tab-mr-md">
                          <FontAwesomeIcon
                            className="text--color-white mr-xxs mt-xxxs"
                            icon={faStar}
                          />
                          <div>
                            <p className="h4 text--small mb-xxxs">
                              {coreStatText}
                            </p>
                            <p className="text--xs">{postStatText}</p>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingBot="none" paddingTop="xs">
          <Rows>
            <Columns count="1">
              <Content>
                <Copy contentToParse={firstTextBlock} />
                <GatsbyImage
                  quality={90}
                  image={inlineImg}
                  alt={image.alternativeText}
                />
                <Copy contentToParse={secondTextBlock} />
                {quoteBox.enabled && (
                  <div className="blog-box">
                    <blockquote className="blog-box__container">
                      <div className="blog-box__mark blog-box__mark--quote">
                        <FontAwesomeIcon
                          icon={faQuoteLeft}
                          className="width--full height--full"
                        />
                      </div>
                      <span className="blog-box__text">
                        <p>{quoteBox.quote}</p>
                      </span>
                      <div className="blog-box__author__container">
                        <div className="blog-box__image">
                          <GatsbyImage
                            alt={quoteBox.profilePicture.alternativeText}
                            image={quoteImage}
                            quality={90}
                          />
                        </div>
                        <div>
                          <p className="blog-box__author">{quoteBox.title}</p>
                        </div>
                      </div>
                    </blockquote>
                  </div>
                )}

                <Copy contentToParse={thirdTextBlock} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <div className="relative border--rounded text-gradient__purple-blue overflow--hidden">
            <Rows>
              <Columns count="2" alignment="center">
                <Content className="py-xl pl-lg pr-lg tab-pr-none">
                  <Heading className="text--color-white" alignment="left">
                    Get the Marketing Edge in your Industry
                  </Heading>
                  <CtaGroup>
                    <Button href="https://lp.lunio.ai/demo-v1">
                      Get Started
                    </Button>
                  </CtaGroup>
                </Content>
                <div className="absolute width--full height--full accent__planet">
                  <StaticImage src="../../images/success-stories/planet-fighting.png" />
                </div>
              </Columns>
            </Rows>
          </div>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="none" paddingBot="lg">
          <Rows gap="lg">
            <Columns count="2,1" alignment="center">
              <Content paddingRight="none">
                <Heading preheading={relatedStories.heading.preHeading}>
                  {relatedStories.heading.primaryHeading}
                </Heading>
              </Content>
            </Columns>
            <Columns count="3">
              {relatedNodes.map(
                ({ coreDetails, slug, cardSettings }, index) => (
                  <RelatedStory
                    key={index}
                    title={coreDetails.name}
                    text={cardSettings.text}
                    realSlug={slug}
                    featuredImage={cardSettings.cover}
                  />
                ),
              )}
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query SuccessStoryListingQuery($id: String!, $related: [Int!]) {
    strapiSuccessStories(id: { eq: $id }) {
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      cardSettings {
        cover {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      slug
      coreDetails {
        diamondColor
      }
      heading {
        postTitle
        title
        statistics {
          coreStatText
          postStatText
        }
      }
      firstTextBlock
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      secondTextBlock
      quoteBox {
        enabled
        quote
        title
        profilePicture {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      thirdTextBlock
      relatedStories {
        heading {
          preHeading
          primaryHeading
        }
      }
    }
    allStrapiSuccessStories(filter: { strapiId: { in: $related } }) {
      nodes {
        slug
        coreDetails {
          name
        }
        cardSettings {
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          text
        }
      }
    }
  }
`

export default SuccessStory
